import React from 'react';
import Form from '../components/Form';

export default function Contact() {
	return (
		<>
			<section className='contact-me'>
				<Form />
			</section>
		</>
	);
}
